import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../context/ContextProvider';

const LoginPage = ({ onLogin }) => {
const {setToken} = useStateContext();
const [error, setError] = useState({});
const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      console.log(formData);
      const response = await axios.post('/login', formData);
      console.log(response);
      console.log(response.data.user);
      setToken(response.data.token);
      navigate('/dashboard');
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.msg);
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
    <div className="card p-4 shadow-lg login-box">
      <h2 className="text-center text-gold mb-4">Alyssum Support</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group mb-3">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name='email'
            className="form-control"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {error?.email && <p className="error">{error.email[0]}</p>}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name='password'
            className="form-control"
            value={formData.password}
            onChange={handleChange}
            required
          />
          {error.password && <p className='error'>{error.password}</p>}
        </div>
        <button type="submit" className="btn btn-outline-gold btn-block">Login</button>
      </form>
    </div>
  </div>

  );
};

export default LoginPage;
