import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CarePackageModal = ({ carePackage, onClose }) => {
  const [name, setName] = useState('');
  const [govRate, setGovRate] = useState('');
  const [nurseRate, setNurseRate] = useState('');

  useEffect(() => {
    if (carePackage) {
      setName(carePackage.name || '');
      setGovRate(carePackage.gov_rate || '');
      setNurseRate(carePackage.nurse_rate || '');
    } else {
      setName('');
      setGovRate('');
      setNurseRate('');
    }
  }, [carePackage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name,
      gov_rate: govRate,
      nurse_rate: nurseRate,
    };

    try {
      if (carePackage) {
        await axios.put(`/care-packages/${carePackage.id}`, data);
      } else {
        await axios.post('/care-packages', data);
      }
      onClose();
    } catch (error) {
      console.error('Error saving care package:', error);
    }
  };

  return (
    <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{carePackage ? 'Edit Care Package' : 'Add Care Package'}</h5>
            <button type="button" className="close" onClick={onClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Gov Rate:</label>
                <input
                  type="number"
                  className="form-control"
                  value={govRate}
                  onChange={(e) => setGovRate(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Nurse Rate:</label>
                <input
                  type="number"
                  className="form-control"
                  value={nurseRate}
                  onChange={(e) => setNurseRate(e.target.value)}
                  required
                />
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">Save</button>
                <button type="button" onClick={onClose} className="btn btn-secondary">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarePackageModal;
