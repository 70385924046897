import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PaymentModal from './PaymentModal';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

const Accounts = ({ userRole }) => {
  const [payments, setPayments] = useState([]);
  const [dues, setDues] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [differences, setDifferences] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const openModal = (payment) => {
    console.log('X');
    setSelectedPayment(payment);
    setShowModal(true);
  };
  const closeModal = () => {
    console.log('X');
    setSelectedPayment(null);
    setShowModal(false);
  };
  useEffect(() => {
    fetchData();
  }, [userRole]);
  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch payments for admin or worker
      if (userRole === 1 || userRole === 2 || userRole === '3') {
        const paymentsResponse = await axios.get('/payments');
        setPayments(paymentsResponse.data.data);

        const duesResponse = await axios.get('/dues');
        console.log("dues", duesResponse.data);
        setDues(duesResponse.data);
      }

      // Fetch invoices for admin or client
      if (userRole === 1 || userRole === 4) {
        const invoicesResponse = await axios.get('/invoices');
        setInvoices(invoicesResponse.data);
      }

      // Fetch differences for admin
      if (userRole === 1) {
        const differencesResponse = await axios.get('/differences');
        console.log(differencesResponse.data);
        setDifferences(differencesResponse.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const generateCycle = async (e) => {
    setLoading(true);
    try {
      const response = await axios.post('/client-invoice');
      console.log(response);
      if (response.status == 200) {
        alert("Cycle generated successfully");
      }
      else {
        alert("Failed to geenrate cycle");
      }
    } catch (error) {
      console.error('Error Generating Cycle:', error);
      alert('Error Generating Cycle:', error);
    }
    fetchData();
    setLoading(false);
  }
  const renderPayments = () => (
    payments.length > 0 ? (
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Invoice ID</th>
            <th>Amount Paid</th>
            <th>Paid At</th>
            <th>Account</th>
            <th>Payment Reference</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.id}>
              <td>{payment.invoice_id}</td>
              <td>{Number(payment.amount_paid).toLocaleString('en-US')}</td>
              <td>{new Date(payment.paid_at).toLocaleDateString()}</td>
              <td>{payment.account}</td>
              <td>{payment.payment_ref}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : <p>No payments found.</p>
  );
  const renderDues = () => (
    dues.length > 0 ? (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Carer</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Amount Payable</th>
            <th>Due Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {dues.map((due) => (
            <tr key={due.id}>
              <td>{due.worker.name + " " + due.worker.lname}</td>
              <td>{new Date(due.cycle.start_date).toLocaleDateString()}</td>
              <td>{new Date(due.cycle.end_date).toLocaleDateString()}</td>
              <td>{Number(due.total_amount).toLocaleString('en-US')}</td>
              <td>{new Date(due.due_date).toLocaleDateString()}</td>
              <td>{due.status}</td>
              <td>
                <Link className="btn btn-warning btn-sm mr-2" to={`/dues/${due.id}`}>View</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : <p>No dues found.</p>
  );
  const renderInvoices = () => (
    invoices.length > 0 ? (
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Invoice ID</th>
            <th>Client</th>
            <th>Status</th>
            <th>Generated At</th>
            <th>Due Date</th>
            <th>Total Amount (AUD)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map(invoice => (
            <tr key={invoice.id}>
              <td>{invoice.id}</td>
              <td>{invoice.client.name + " " + invoice.client.lname}</td>
              <td>{invoice.status}</td>
              <td>{new Date(invoice.created_at).toLocaleDateString("en-AU")}</td>
              <td>{new Date(invoice.due_date).toLocaleDateString("en-AU")}</td>
              <td className="text-end">
                {Number(invoice.total_amount).toLocaleString("en-AU", { style: "currency", currency: "AUD" })}
              </td>
              <td>
                <td>
                  <Link className="btn btn-warning btn-sm mr-2" to={`/invoice/${invoice.id}`}>View</Link>
                </td>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : <p>No invoices found.</p>
  );

  const generateInvoicePDF = async (invoiceId) => {
    try {
      // Fetch shifts related to the invoice with status 'complete' and matching client_id
      const response = await axios.get(`/invoices/${invoiceId}/shifts`);
      const shifts = response.data;

      const doc = new jsPDF();
      doc.text(`Invoice ID: ${invoiceId}`, 10, 10);
      doc.text("Shifts Billed in This Invoice", 10, 20);

      const tableColumn = ["Shift ID", "Client ID", "Start Time", "End Time", "Fee (AUD)"];
      const tableRows = [];

      shifts.forEach(shift => {
        const shiftData = [
          shift.id,
          shift.client_id,
          new Date(shift.started_at).toLocaleString("en-AU"),
          new Date(shift.ended_at).toLocaleString("en-AU"),
          shift.fee.toLocaleString("en-AU", { style: "currency", currency: "AUD" })
        ];
        tableRows.push(shiftData);
      });

      doc.autoTable(tableColumn, tableRows, { startY: 30 });
      const totalFee = shifts.reduce((acc, shift) => acc + shift.fee, 0);
      doc.text(`Total Fee: ${totalFee.toLocaleString("en-AU", { style: "currency", currency: "AUD" })}`, 10, doc.autoTable.previous.finalY + 10);

      doc.save(`Invoice_${invoiceId}.pdf`);
    } catch (error) {
      console.error("Error generating PDF: ", error);
      alert("Failed to generate PDF.");
    }
  };
  const renderDifferences = () => (
    differences.length > 0 ? (
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Cycle Id</th>
            <th>Started On</th>
            <th>Ended On</th>
            <th>Income Total</th>
            <th>Dues Total</th>
            <th>Difference</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {differences.map(diff => (
            <tr key={diff.id}>
              <td>{diff.id}</td>
              <td>{new Date(diff.start_date).toLocaleDateString('en-US')}</td>
              <td>{new Date(diff.end_date).toLocaleDateString('en-US')}</td>
              <td className='text-end'>{Number(diff.income_total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td className='text-end'>{Number(diff.dues_total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td className='text-end'>{Number(diff.income_total - diff.dues_total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td><Link className="btn btn-warning btn-sm mr-2" to={`/billing-cycle/${diff.id}`}>View</Link>              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : <p>No differences found.</p>
  );
  if (loading) {
    return <Loader />
  }
  return (
    <div className="container mt-5">
      <h3>Accounts</h3>
      <Tabs defaultActiveKey={
        userRole === 1 ? "difference"
          : (userRole === 2 || userRole === 3) ? "dues"
            : userRole === 4 ? "invoices"
              : "unauthorized"
      } id="account-tabs">
        {/* Admin Only: Difference Tab */}
        {userRole === 1 && (
          <Tab eventKey="difference" title="Billing Cycles">
            <div className="mt-4">
              <h5>Difference between Invoices and Payments</h5>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => generateCycle()}>
                  Generate Cycle
                </button>

              </div>
              {renderDifferences()}
            </div>
          </Tab>
        )}
        {/* Admin/Client: Invoices Tab */}
        {(userRole === 1 || userRole === 4) && (
          <Tab eventKey="invoices" title="Invoices">
            <div className="mt-4">
              <h5>Invoice History</h5>
              {renderInvoices()}
            </div>
          </Tab>
        )}

        {/* Admin/Worker: Payments Tab */}
        {(userRole === 1) && (
          <Tab eventKey="payments" title="Payments">
            <div className="mt-4">
              <h5>Payment History</h5>
              <button onClick={() => openModal(null)} className="btn btn-primary">Record Payment</button>
              {renderPayments()}
              <PaymentModal show={showModal} onClose={closeModal} paymentData={selectedPayment} />
            </div>
          </Tab>
        )}
        {/*  Nurse Dues */}
        {(userRole === 1 || userRole === 2 || userRole === 3) && (
          <Tab eventKey="dues" title="My Dues">
            <div className="mt-4">
              <h5>{userRole == 1 ? "My Dues" : "Dues"}</h5>
              {renderDues()}
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default Accounts;