import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../context/ContextProvider';

function RegisterAdmin() {
  const [formData, setFormData] = useState({
    name: '',
    mname: '',
    lname: '',
    email: '',
    tel: 0,
    level: 1,
    state: '',
    zipcode: '',
    password: '',
    password_confirmation: '',
  });
  const { setToken } = useStateContext;
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/users', formData);
      console.log(response);
      alert(response.data.message);
      setToken(response.data.token);
      navigate('/dashboard');
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response);
        setError(err.response.data.data.msg);
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };
  return (
    <div className="register-admin container mt-5 shadow">
      <h2 className="text-center mb-4">Register Admin</h2>
      {error && <p className="alert alert-danger">{error}</p>}
      <form onSubmit={handleSubmit} className="row g-3">
        <div className="col-md-4">
          <label className="form-label">First Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-control"
            required
          />
          {error?.name && <p className="error">{error.name[0]}</p>}
        </div>
        <div className="col-md-4">
          <label className="form-label">Middle Name</label>
          <input
            type="text"
            name="mname"
            value={formData.mname}
            onChange={handleChange}
            className="form-control"
          />
          {error?.mname && <p className="error">{error.mname[0]}</p>}
        </div>
        <div className="col-md-4">
          <label className="form-label">Last Name</label>
          <input
            type="text"
            name="lname"
            value={formData.lname}
            onChange={handleChange}
            className="form-control"
            required
          />
          {error?.lname && <p className="error">{error.lname[0]}</p>}
          <input
            type="hidden"
            name="level"
            value={formData.level}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>
        <div className="col-md-6">
          <label className="form-label">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-control"
            required
          />
          {error?.email && <p className="error">{error.email[0]}</p>}
        </div>
        <div className="col-md-6">
          <label className="form-label">Telephone</label>
          <input
            type="tel"
            name="tel"
            value={formData.tel}
            onChange={handleChange}
            className="form-control"
            required
          />
          {error?.tel && <p className="error">{error.tel[0]}</p>}
        </div>
        <div className="col-md-6">
          <label className="form-label">State</label>
          <select
            name="state"
            value={formData.state}
            onChange={handleChange}
            className="form-control"
            required>
            <option value="">Select State</option>
            <option value="1">Victoria</option>
            {/* <option value="3">Intern</option>
            <option value="4">Client</option> */}
          </select>
          {error?.state && <p className="error">{error.state[0]}</p>}
        </div>
        <div className="col-md-6">
          <label className="form-label">Zipcode</label>
          <input
            type="text"
            name="zipcode"
            value={formData.zipcode}
            onChange={handleChange}
            className="form-control"
            required
          />
          {error?.zipcode && <p className="error">{error.zipcode[0]}</p>}
        </div>
        <div className="col-md-6">
          <label className="form-label">Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="form-control"
            required
          />
          {error?.password && <p className="error">{error.password[0]}</p>}
        </div>
        <div className="col-md-6">
          <label className="form-label">Confirm Password</label>
          <input
            type="password"
            name="password_confirmation"
            value={formData.password_confirmation}
            onChange={handleChange}
            className="form-control"
            required
          />
          {error?.password_confirmation && <p className="error">{error.password_confirmation[0]}</p>}
        </div>
        <div className="col-12 text-center">
          <button type="submit" className="btn btn-gold w-50">Register Admin</button>
        </div>
      </form>
    </div>
  );
}

export default RegisterAdmin;