import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';

const Shift = ({ role }) => {
  const { id } = useParams();

  const isAdmin = role === 1;
  const isNurse = role === 2 || role === 3;
  const isClient = role === 4;

  // Get shift ID from route parameters
  const [shift, setShift] = useState({});
  const [observations, setObservations] = useState([]);
  const [newObservation, setNewObservation] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // For navigation after actions
  const [loading, setLoading] = useState(false);

  // Fetch shift details from the API
  useEffect(() => {
    const fetchshift = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/shifts/${id}`);
        console.log(response.data.data);
        setShift(response.data.data);
        setObservations(response.data.data.observations);
      } catch (error) {
        console.error('Error fetching shift:', error);
        setError('Failed to load shift data');
      }
      setLoading(false);
    };
    fetchshift();
  }, [id]);

  const handleAddObservation = async (e) => {
    const newObs = {
      observation: newObservation,
      shift_id: id,
    };
    setLoading(true);

    try {
      const response = await axios.post(`/observations`, newObs);
      setObservations([...observations, response.data.data]);
      console.log(response);
      alert('Observation added successfully');
      console.log(response.data.data);
    } catch (error) {
      console.error('Error recording observation:', error);
      setError('Failed to record observation');
    }
    setNewObservation(''); // Clear input
    setLoading(false);
  };

  const handleStartShift = async () => {
    setLoading(true);
    try {
      const status = 'ongoing';
      const response = await axios.post(`/start-shift/${id}`, { status });
      console.log(response);
      alert('Shift Started Successfully');
      setShift({ ...shift, status: 'ongoing' });
    } catch (error) {
      console.error('Error starting shift:', error);
      setError('Failed to start shift');
    }
    setLoading(false);
  };
  const handleApproveShift = async () => {
    setLoading(true);
    try {
      const status = 'approved';
      console.log(status);
      const response = await axios.post(`/approve-shift/${id}`, { status });
      console.log(response);
      alert('Shift Approved Successfully');
      setShift({ ...shift, status: 'approved' });
    } catch (error) {
      console.error('Error approving shift:', error);
      setError('Failed to approve shift');
    }
    setLoading(false);
  };

  const handleEndShift = async () => {
    setLoading(true);
    try {
      const status = 'closed';
      const response = await axios.post(`/end-shift/${id}`, { status });
      console.log(response);
      alert('Shift Ended Successfully');
      setShift({ ...shift, status: 'closed' });
    } catch (error) {
      console.error('Error ending shift:', error);
      setError('Failed to end shift');
    }
    setLoading(false);
  };

  const handleCancelShift = async () => {
    setLoading(true);
    try {
      const status = 'cancelled';
      const response = await axios.post(`/cancel-shift/${id}`, { status });
      console.log(response);
      alert('Shift Cancelled');
      setShift({ ...shift, status: 'cancelled' });
    } catch (error) {
      console.error('Error canceling shift:', error);
      setError('Failed to cancel shift');
    }
    setLoading(false);
  };

  const renderActionButton = () => {
    switch (shift.status) {
      case 'pending':
        return (
          <>
            {isAdmin && <button className="btn btn-success" onClick={handleApproveShift}>Approve Shift</button>}
            <button className="btn btn-warning" onClick={handleCancelShift}>Cancel Shift</button>
          </>
        );
      case 'approved':
        return (
          <>
            <button className="btn btn-success mr-2" onClick={handleStartShift}>Start Shift</button>
            <button className="btn btn-warning" onClick={handleCancelShift}>Cancel Shift</button>
          </>
        );
      case 'ongoing':
        return (
          <>
            {isNurse && <button className="btn btn-danger mr-2" onClick={handleEndShift}>End Shift</button>}
          </>
        );
      case 'completed':
        return <span className="badge badge-success">Shift Completed</span>;
      case 'cancelled':
        return <span className="badge badge-danger">Shift Cancelled</span>;
      default:
        return (
          <button className="btn btn-warning" onClick={handleCancelShift}>Cancel Shift</button>
        );
    }
  };
  if (loading) {
    return <Loader></Loader>
  }
  return (
    <div className="container mt-5">
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="d-flex justify-content-between mb-4">
        <h3>{shift.shiftName ? shift.shiftName : 'Shift'} Details</h3>
        {renderActionButton()}
      </div>

      <Tabs defaultActiveKey="details" id="shift-tabs">
        <Tab eventKey="details" title="Details">
          <div className="mt-4">
            <h5>Shift Information</h5>
            <p><strong>Start Time:</strong>{new Date(shift.start_at).toLocaleString("en-US")}</p>
            <p><strong>End Time:</strong>{new Date(shift.end_at).toLocaleString("en-US")} </p>
            <p><strong>Client</strong> {shift.client?.name + " " + shift.client?.lname || 'N/A'}</p>
            <p><strong>Worker</strong> {shift.worker?.name + " " + shift.worker?.lname || 'N/A'}</p>
            <p><strong>Status:</strong> {shift.status ? shift.status.charAt(0).toUpperCase() + shift.status.slice(1) : 'Unknown'}</p>
          </div>
        </Tab>

        <Tab eventKey="observations" title="Observations">
          <div className="mt-4">
            <h5>Shift Notes</h5>
            {observations.length === 0 ? (
              <p>No observations yet.</p>
            ) : (
              <ul className="list-group">
                {observations.map((obs) => (
                  <li className="list-group-item" key={obs.id}>
                    <p>{obs.observation}</p>
                    <small>{obs.created_at}</small>
                  </li>
                ))}
              </ul>
            )}

            <div className="mt-4">
              <h6>Add a new observation</h6>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={newObservation}
                  onChange={(e) => setNewObservation(e.target.value)}
                  placeholder="Enter your observation..."
                />
              </div>
              <button className="btn btn-primary" onClick={handleAddObservation} disabled={!newObservation}>
                Add Observation
              </button>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Shift;
