import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useStateContext } from '../context/ContextProvider';

const ShiftModal = ({ shift, error, setShowModal, handleSave }) => {
  const [nurseId, setNurseId] = useState('');
  const [clientId, setClientId] = useState('');
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');
  const [shiftType, setShiftType] = useState(1); // Default to Active
  const {token} = useStateContext();


  const [nurses, setNurses] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (shift) {
      setNurseId(shift.nurse_id || '');
      setClientId(shift.client_id || '');
      setStartAt(shift.start_at || '');
      setEndAt(shift.end_at || '');
      setShiftType(shift.shift_type || 'Active');
    }
  }, [shift]);

  useEffect(() => {
    // Fetch nurses and clients data from the API
    axios.get('/nurses').then(response => setNurses(response.data.data));
    axios.get('/clients').then(response => setClients(response.data.data));
  }, []);

  const handleFormSave = () => {
    const shiftData = {
      nurse_id: nurseId,
      client_id: clientId,
      start_at: startAt,
      end_at: endAt,
      shift_type: shiftType,
    };
    handleSave(shiftData);
  };

  return (
    <div className="modal show d-block" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{shift ? 'Edit Shift' : 'Add Shift'}</h5>
            <button className="close" onClick={() => setShowModal(false)}>&times;</button>
          </div>
          <div className="modal-body">
            {/* Nurse Selection */}
            <div className="form-group">
              <label>Nurse</label>
              <select className="form-control" value={nurseId} onChange={(e) => setNurseId(e.target.value)}>
                <option value="">Select Support Worker</option>
                {nurses.map((nurse) => (
                  <option key={nurse.id} value={nurse.id}>
                    {nurse.name}
                  </option>
                ))}
              </select>
              {error.nurse_id && <p className='error'>{error.nurse_id}</p>}
            </div>

            {/* Client Selection */}
            <div className="form-group">
              <label>Client</label>
              <select className="form-control" value={clientId} onChange={(e) => setClientId(e.target.value)}>
                <option value="">Select Client</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>
              {error.client_id && <p className='error'>{error.client_id}</p>}

            </div>

            {/* Start Time */}
            <div className="form-group">
              <label>Start Time</label>
              <input type="datetime-local" className="form-control" value={startAt} onChange={(e) => setStartAt(e.target.value)} />
              {error.start_at && <p className='error'>{error.start_at}</p>}
            </div>

            {/* End Time */}
            <div className="form-group">
              <label>End Time</label>
              <input type="datetime-local" className="form-control" value={endAt} onChange={(e) => setEndAt(e.target.value)} />
              {error.end_at && <p className='error'>{error.end_at}</p>}
            </div>

            {/* Shift Type */}
            <div className="form-group">
              <label>Shift Type</label>
              <select className="form-control" value={shiftType} onChange={(e) => setShiftType(e.target.value)}>
                <option value="1">Active</option>
                <option value="2">Inactive</option>
              </select>
              {error.shift_type && <p className='error'>{error.shift_type}</p>}
            </div>
          </div>

          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
            <button className="btn btn-primary" onClick={handleFormSave}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftModal;