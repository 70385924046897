import React from 'react';
import AdminDashboard from './AdminDashboard';
import NurseDashboard from './NurseDashboard';
import ClientDashboard from './ClientDashboard';
import { Navigate } from 'react-router-dom';
import { useStateContext } from '../context/ContextProvider';

const Dashboard = ({ userRole }) => {
  const { token } = useStateContext();

  if (!token) {
    return <Navigate to={'/login'}></Navigate>
  }
  const renderDashboard = () => {
    switch (userRole) {
      case 1:
        return <AdminDashboard />;
      case 2:
      case 3:
        return <NurseDashboard />;
      case 4:
        return <ClientDashboard />;
      default:
        return <div>Unauthorized</div>;
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">Welcome to Your Dashboard</h2>
      {renderDashboard()}
    </div>
  );
};

export default Dashboard;
