import React, { useState } from 'react';

const UserModal = ({ user, setShowModal, users, setUsers }) => {
  const [name, setName] = useState(user ? user.name : '');
  const [role, setRole] = useState(user ? user.role : 'Client');
  const [email, setEmail] = useState(user ? user.email : '');

  const handleSave = () => {
    if (user) {
      // Edit user
      setUsers(users.map(u => u.id === user.id ? { ...u, name, role, email } : u));
    } else {
      // Add new user
      const newUser = {
        id: users.length + 1,
        name,
        role,
        email,
      };
      setUsers([...users, newUser]);
    }
    setShowModal(false);
  };

  return (
    <div className="modal show d-block" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{user ? 'Edit User' : 'Add User'}</h5>
            <button className="close" onClick={() => setShowModal(false)}>&times;</button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label>Name</label>
              <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Role</label>
              <select className="form-control" value={role} onChange={(e) => setRole(e.target.value)}>
                <option value="Admin">Admin</option>
                <option value="Nurse">Nurse</option>
                <option value="Client">Client</option>
              </select>
            </div>
            <div className="form-group">
              <label>Email</label>
              <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
            <button className="btn btn-primary" onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
