import { createContext, useContext, useEffect, useState, useCallback } from "react";
import axios from "axios";

const stateContext = createContext();

export const ContextProvider = ({children})=>{
    const [user, setUser] = useState({});
    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN'));

    const setToken = (token) => {
        _setToken(token);
        if (token) {
            localStorage.setItem('ACCESS_TOKEN', token);
        }
        else{
            localStorage.removeItem('ACCESS_TOKEN');
        }
    }
    const getUser = useCallback(async () => {
        try {
            const response = await axios.get('/user', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            alert("Welcome back " + response.data.name);
            console.log(response.data);
            setUser(response.data);
            
        } catch (err) {
          if (err.response && err.response.data) {
            console.log(err);
          } else {
            console.log(err);
          }
        }
    }, [token]);
    useEffect(()=>{
        if (token) {
            getUser();
        }
    }, [token, getUser]);
    return (
        <stateContext.Provider value={{
            user,
            token,
            setToken,
            setUser
          }}>
            {children}
        </stateContext.Provider>
    )

}
export const useStateContext = () => useContext(stateContext);