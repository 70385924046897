import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useStateContext } from '../context/ContextProvider';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faUser,
    faBuilding,
    faBox,
    faClock,
    faUsers,
    faFileAlt,
    faSignOutAlt,
    faSignInAlt,
  } from '@fortawesome/free-solid-svg-icons';
  

const Layout = ({ children, role }) => {
    const [error, setError] = useState(null);
    const { setToken, setUser, user, token } = useStateContext();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const navigate = useNavigate();
    const isAdmin = role === 1;
    const isNurse = role === 2;
    const isClient = role === 4;

    const signOut = async (e) => {
        try {
            const response = await axios.post('/logout');
            alert(response.data.message);
            setToken(null);
            setUser({});
            localStorage.removeItem("ACCESS_TOKEN");
            navigate('/login');
        } catch (err) {
            if (err.response && err.response.data) {
                console.log(err);
                alert(err.response.data.message);
                setError(err.response.data.message);
            } else {
                setError('An error occurred. Please try again.');
            }
        }
        console.log("Sign Out");
    }
    const toggleSidebar = () => setIsCollapsed(!isCollapsed);
    return (
        <div className="d-flex">
            {/* Sidebar */}
            <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
                <button className="toggle-btn" onClick={toggleSidebar}>
                    {isCollapsed ? '☰' : '✖'}
                </button>
                <div className="sidebar-content">
                    {/* Logo or User Info */}
                    <div className="brand">
                        <img
                            src="/images/required/logo.jpeg" // Replace with actual path for the logo
                            alt="Brand Logo"
                            className="brand-logo"
                        />
                        {!isCollapsed && <span className="brand-name text-gold">Alyssum Support</span>}
                    </div>

                    {user && (
                        <div className="user-info text-center">
                            <img
                                src={user.profilePicture || '/images/required/agent-6.jpg'}
                                alt={user.name}
                                className="rounded-circle profile-pic"
                            />
                            {!isCollapsed && <p className="text-light">{user.name}</p>}
                        </div>
                    )}
                    {/* Menu Items */}
                    <nav className="nav flex-column">
                        <Link to="/dashboard" className="nav-link d-flex">
                            <FontAwesomeIcon icon={faHome} className="icon p-1" />
                            Dashboard
                        </Link>
                        {token && (
                            <Link to="/accounts" className="nav-link d-flex">
                                <FontAwesomeIcon icon={faUser} className="icon p-2" />
                                Accounts
                            </Link>
                        )}
                        {isAdmin && (
                            <Link to="/agencies" className="nav-link d-flex">
                                <FontAwesomeIcon icon={faBuilding} className="icon p-2" />
                                Agents
                            </Link>
                        )}
                        {isAdmin && (
                            <Link to="/care-packages" className="nav-link d-flex">
                                <FontAwesomeIcon icon={faBox} className="icon p-2" />
                                Care Packages
                            </Link>
                        )}
                        {token && (
                            <Link to="/shifts" className="nav-link d-flex">
                                <FontAwesomeIcon icon={faClock} className="icon p-2" />
                                Shifts
                            </Link>
                        )}
                        {isAdmin && (
                            <Link to="/users" className="nav-link d-flex">
                                <FontAwesomeIcon icon={faUsers} className="icon p-1" />
                                Users
                            </Link>
                        )}
                        {isClient && (
                            <Link to="/requests" className="nav-link d-flex">
                                <FontAwesomeIcon icon={faFileAlt} className="icon p-2" />
                                Requests
                            </Link>
                        )}
                        {token ? (
                            <button className="btn btn-outline-gold mt-3 d-flex align-items-center nav-link" onClick={signOut}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="icon p-2" />
                                Sign Out
                            </button>
                        ) : (
                            <Link to="/login" className="nav-link d-flex">
                                <FontAwesomeIcon icon={faSignInAlt} className="icon p-2" />
                                Sign In
                            </Link>
                        )}
                    </nav>
                </div>
            </div>

            {/* Main Content */}
            <main style={{
                marginLeft: isCollapsed ? '80px' : '250px', // Adjust based on sidebar state
            }} className="container mt-5">
                {children}
            </main>
        </div>
    );
};

export default Layout;