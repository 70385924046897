const PdfLayout = () => {

    return (
        <header className="text-center mb-4">
            <div className="text-center">
                <img
                    src="/images/required/logo.jpeg" // Replace with actual path for the logo
                    alt="Brand Logo"
                    className="pdf-logo"
                />
                <h3>Alyssum Support</h3>
            </div>
            <p>support@alyssumsupport.com | +61 450 885 370</p>
        </header>
    );
};
export default PdfLayout;

