// Agents.js
import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import AgentModal from './AgentModal';
import Loader from '../components/Loader';


const Agents = () => {
    const [agents, setAgents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentAgent, setCurrentAgent] = useState({ name: '', email: '', phone: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);  

    useEffect(() => {
        fetchAgents();
    }, []);

    const fetchAgents = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/agents');
            setAgents(response.data);
        } catch (error) {
            console.error("Error fetching agents:", error);
        }
        setLoading(false);
    };

    const handleShowModal = (agent = null) => {
        if (agent) {
            setCurrentAgent(agent);
            setIsEditing(true);
        } else {
            setCurrentAgent({ name: '', email: '', phone: '' });
            setIsEditing(false);
        }
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentAgent({ ...currentAgent, [name]: value });
    };

    const handleSaveAgent = async () => {
        try {
            if (isEditing) {
                await axios.put(`/agents/${currentAgent.id}`, currentAgent);
            } else {
                await axios.post('/agents', currentAgent);
            }
            fetchAgents();
            handleCloseModal();
        } catch (error) {
            console.error("Error saving agent:", error);
        }
    };

    const handleDeleteAgent = async (id) => {
        try {
            await axios.delete(`/agents/${id}`);
            fetchAgents();
        } catch (error) {
            console.error("Error deleting agent:", error);
        }
    };
    if (loading) {
        return <Loader></Loader>
      }
    return (
        <div>
            <h2>Agents</h2>
            <Button variant="primary" onClick={() => handleShowModal()}>Add Agent</Button>
            <Table striped bordered hover className="mt-3">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {agents.map(agent => (
                        <tr key={agent.id}>
                            <td>{agent.name + " " + agent.lname}</td>
                            <td>{agent.email}</td>
                            <td>{agent.tel}</td>
                            <td>
                                <Button variant="info" onClick={() => handleShowModal(agent)}>Edit</Button>{' '}
                                <Button variant="danger" onClick={() => handleDeleteAgent(agent.id)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Reusable Modal for Add/Edit */}
            <AgentModal
                show={showModal}
                handleClose={handleCloseModal}
                agent={currentAgent}
                handleInputChange={handleInputChange}
                handleSubmit={handleSaveAgent}
                isEditing={isEditing}
            />
        </div>
    );
};

export default Agents;
