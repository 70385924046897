import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CarePackageModal from './CarePackageModal';
import Loader from '../components/Loader';

const CarePackages = () => {
  const [carePackages, setCarePackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);  

  useEffect(() => {
    fetchCarePackages();
  }, []);

  const fetchCarePackages = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/care-packages');
      setCarePackages(response.data);
    } catch (error) {
      console.error('Error fetching care packages:', error);
    }
    setLoading(false);
  };

  const deleteCarePackage = async (id) => {
    if (window.confirm('Are you sure you want to delete this care package?')) {
      try {
        await axios.delete(`/care-packages/${id}`);
        fetchCarePackages();
      } catch (error) {
        console.error('Error deleting care package:', error);
      }
    }
  };

  const openModal = (carePackage = null) => {
    setSelectedPackage(carePackage);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedPackage(null);
    setShowModal(false);
    fetchCarePackages();
  };
  
  if (loading) {
    return <Loader></Loader>
  }

  return (
    <div>
      <h1>Care Packages</h1>
      <button onClick={() => openModal()} className="btn btn-primary">Add Care Package</button>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Gov Rate/Hr($)</th>
            <th>Nurse Rate/Hr ($)</th>
            <th>Mark Up ($)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {carePackages.length >= 1 ?
            (carePackages.map((pkg) => (
              <tr key={pkg.id}>
                <td>{pkg.name}</td>
                <td>{pkg.gov_rate}</td>
                <td>{pkg.nurse_rate}</td>
                <td>{pkg.gov_rate - pkg.nurse_rate}</td>
                <td>
                  <button onClick={() => openModal(pkg)} className="btn btn-warning">Edit</button>
                  <button onClick={() => deleteCarePackage(pkg.id)} className="btn btn-danger">Delete</button>
                </td>
              </tr>
            ))) : (
              <tr>
                <td className='text-center p-2' colSpan={4}>No packages have been listed yet.</td>
              </tr>
            )}
        </tbody>
      </table>
      {showModal && <CarePackageModal carePackage={selectedPackage} onClose={closeModal} />}
    </div>
  );
};

export default CarePackages;