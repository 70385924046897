import React from 'react';
import './../App.css';  // Import your loader styles

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="spinner-grow loader">
        <img
            src="/images/required/logo.jpeg" // Replace with actual path for the logo
            alt="Brand Logo"
            className="loader-image"
        />
      </div>
    </div>
  );
};

export default Loader;
