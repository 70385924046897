import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
//import DefaultLayout from './components/DefaultLayout';
import './App.css';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Shifts from './pages/Shifts';
import Shift from './pages/Shift';
import Accounts from './pages/Accounts';
import Agents from './pages/Agents';
import CarePackages from './pages/CarePackages';
//import { ContextProvider } from './context/ContextProvider';
import { useStateContext } from './context/ContextProvider';
import RegisterAdmin from './pages/RegisterAdmin';
import RegisterUser from './pages/RegisterUser';
import axios from 'axios';
import Dues from './pages/Dues';
import Invoice from './pages/Invoice';
import Cycle from './pages/Cycle';
axios.defaults.baseURL = 'https://alyssumsupport.com/api';
//axios.defaults.baseURL = 'http://127.0.0.1:8000/api';

function App() {
  const { token, user } = useStateContext(); // Get token and user from context
  const [role, setRole] = useState(null);
  useEffect(() => {
    if (user) {
      console.log(user.level);
      setRole(user.level);
    }
  }, [user]);
  useEffect(() => {
    if (token) {
      axios.defaults.headers.Authorization = `Bearer ${token}`;
      console.log(token);
    }
  }, [token])
  // useEffect(() => {
  //   // Check if there are users in the system
  //   const checkUsers = async (e) => {
  //     try {
  //       const response = await axios.get('/check-users');
  //       setNoUsers(response.data.noUsers);
  //     } catch (err) {
  //       console.error('Error checking users:', err);
  //     }
  //   };
  //   checkUsers();
  // }, []);
  // Replace with your user state and role management

  return (
    <Router>
      <Layout user={user} role={role}>
        <Routes>
          <Route path="/" element={<Dashboard userRole={role} />} />
          <Route path="/dashboard" element={<Dashboard userRole={role} />} />
          <Route path="/accounts" element={<Accounts userRole={role} />} />
          <Route path="/agencies" element={<Agents />} />
          <Route path="/shifts" element={<Shifts />} />
          <Route path="/users" element={<Users />} />
          <Route path="/shift/:id" element={<Shift role={role} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register-admin" element={<RegisterAdmin />} />
          <Route path="/register-user" element={<RegisterUser />} />
          <Route path="/care-packages" element={<CarePackages />} />
          <Route path='/dues/:dueId' element={<Dues />} />
          <Route path='/invoice/:invoiceId' element={<Invoice />} />
          <Route path='/billing-cycle/:cycleId' element={<Cycle />} />
        </Routes>
      </Layout>
    </Router>
   
  );
}

export default App;
