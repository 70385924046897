import React, { useState, useEffect } from 'react';
import UserModal from './UsersModal';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('all'); // New state for filtering
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch users based on selected filter
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        let response;
        if (filter === 'nurses') {
          response = await axios.get('/nurses');
        } else if (filter === 'clients') {
          response = await axios.get('/clients');
        } else {
          response = await axios.get('/users');
        }
        setUsers(response.data.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      setLoading(false);
    };
    fetchUsers();
  }, [filter]); // Add filter as a dependency

  // Handle view, edit, and delete actions
  const handleView = (id) => navigate(`/user/${id}`);
  const handleEdit = (id) => navigate(`/user/${id}/edit`);
  const handleDelete = async (id) => {
    try {
      await axios.delete(`/users/${id}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` }
      });
      setUsers(users.filter(user => user.id !== id));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleAddUser = () => {
    setSelectedUser(null); // Null for new user
    setShowModal(true);
  };

  if (loading) return <Loader />;

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between mb-4">
        <h3>Users</h3>
        <Link to={'/register-user'} className='btn btn-primary'>Add User</Link>
      </div>

      {/* Filter Navbar */}
      <div className="btn-group mb-3">
        <button className={`btn ${filter === 'all' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFilter('all')}>All</button>
        <button className={`btn ${filter === 'nurses' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFilter('nurses')}>Nurses</button>
        <button className={`btn ${filter === 'clients' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFilter('clients')}>Clients</button>
      </div>

      {users.length === 0 ? (
        <p>No users registered yet.</p>
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Telephone</th>
              <th>Level</th>
              <th>Profile Picture</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id}>
                <td>{`${user.name} ${user.mname || ''} ${user.lname || ''}`}</td>
                <td>{user.email}</td>
                <td>{user.tel}</td>
                <td>{user.level === 1 ? "Admin" : user.level === 2 || user.level === 3 ? "Support Worker" : "Client"}</td>
                <td>
                  <img src={user.profilePicture || '/images/required/agent-6.jpg'} alt="Profile" width="50" height="50" />
                </td>
                <td>
                  <button className="btn btn-primary" onClick={() => handleView(user.id)}>View</button>
                  <button className="btn btn-secondary" onClick={() => handleEdit(user.id)}>Edit</button>
                  <button className="btn btn-danger" onClick={() => handleDelete(user.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal for Create/Edit User */}
      {showModal && (
        <UserModal
          user={selectedUser}
          setShowModal={setShowModal}
          users={users}
          setUsers={setUsers}
        />
      )}
    </div>
  );
};

export default Users;
