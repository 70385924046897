import { Link } from "react-router-dom";

const NurseDashboard = () => {
    return (
      <div className="card p-4 shadow-sm">
        <h3>Team Player Dashboard</h3>
        <div className="row mt-4">
          <div className="col-md-4">
            <div className="card p-3 mb-4">
              <Link to={'/shifts'}><h5>Appointments</h5></Link>
              <p>Check upcoming appointments</p>
            </div>
          </div>  
          <div className="col-md-4">
            <div className="card p-3 mb-4">
              <Link to={'/accounts'}><h5>Accounts</h5></Link>
              <p>View and track your payments</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card p-3 mb-4">
              <Link to={'/accounts'}><h5>Current Dues</h5></Link>
              <p>You currently have 24 hours unpaid</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default NurseDashboard;
  