import { Link } from "react-router-dom";
const AdminDashboard = () => {
  return (
    <div className="card p-4 shadow-sm">
      <h3>Admin Dashboard</h3>
      <div className="row mt-4">
        <div className="col-md-4">
          <div className="card p-3 mb-4">
            <Link to={'/users'}><h5>Total Users</h5></Link>
            <p>View and manage all users</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card p-3 mb-4">
            <Link to={'/accounts'}><h5>Check Finances</h5></Link>
            <p>Track the current financial status</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card p-3 mb-4">
            <Link to={'/shifts'}><h5>Manage Shifts</h5></Link>
            <p>Assign and manage shifts</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
