import { Link } from "react-router-dom";

const ClientDashboard = () => {
    return (
      <div className="card p-4 shadow-sm">
        <h3>Client Dashboard</h3>
        <div className="row mt-4">
          <div className="col-md-4">
            <div className="card p-3 mb-4">
              <Link to={'/my-records'}><h5>Health Records</h5></Link>
              <p>View your health data and progress</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card p-3 mb-4">
              <Link to={'/shifts?status=pending'}><h5>Upcoming Appointments</h5></Link>
              <p>Check your next appointments</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card p-3 mb-4">
            <Link to={'/shifts'}><h5>All Shifts</h5></Link>
              <p>View all your shifts</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ClientDashboard;
  