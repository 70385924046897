import React, { useState, useEffect } from 'react';
import ShiftModal from './ShiftModal';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useStateContext } from '../context/ContextProvider';
import Loader from '../components/Loader';

const Shifts = () => {
  const [shifts, setShifts] = useState([]);
  const [filteredShifts, setFilteredShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { token } = useStateContext();
  const [error, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState('table');
  const [statusFilter, setStatusFilter] = useState('');

  // Fetch shifts from the API
  useEffect(() => {
    const fetchShifts = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/shifts');
        setShifts(response.data.data);
        setFilteredShifts(response.data.data); // Initially, show all shifts
      } catch (error) {
        setErrors(error.response?.data?.error || 'An error occurred');
      }
      setLoading(false);
    };
    fetchShifts();
  }, []);
  const statusColors = {
    pending: '#FFA500', // Orange
    approved: '#4CAF50', // Green
    ongoing: '#2196F3', // Blue
    closed: '#9E9E9E',  // Grey
    cancelled: '#F44336', // Red
  };
  const handleAddShift = () => {
    setSelectedShift(null);
    setShowModal(true);
  };

  const handleEditShift = (shift) => {
    setSelectedShift(shift);
    setShowModal(true);
  };

  const handleDeleteShift = async (shiftId) => {
    try {
      await axios.delete(`/shifts/${shiftId}`);
      setShifts(shifts.filter(shift => shift.id !== shiftId));
    } catch (error) {
      console.error('Error deleting shift:', error);
    }
  };

  const handleSaveShift = (shiftData) => {
    if (selectedShift) {
      axios.put(`/shifts/${selectedShift.id}`, shiftData)
        .then(response => {
          setShifts(shifts.map(shift => shift.id === selectedShift.id ? response.data.data : shift));
          setShowModal(false);
        })
        .catch(error => {
          console.error('Error updating shift:', error);
        });
    } else {
      axios.post('/shifts', shiftData)
        .then(response => {
          setShifts([...shifts, response.data.data]);
          setShowModal(false);
        })
        .catch(error => {
          setErrors(error);
          console.error('Error adding shift:', error);
        });
    }
  };

  // Filter shifts based on status
  useEffect(() => {
    if (statusFilter) {
      setFilteredShifts(shifts.filter(shift => shift.status === statusFilter));
    } else {
      setFilteredShifts(shifts);
    }
  }, [statusFilter, shifts]);

  // View toggle
  const toggleViewMode = () => {
    setViewMode(viewMode === 'table' ? 'timetable' : 'table');
  };

  if (loading) return <Loader />;

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Shifts</h3>
        <button className="btn btn-primary" onClick={handleAddShift}>Add Shift</button>
      </div>

      {/* Filter Navbar */}
      <div className="mb-4">
        <button className={`btn btn-sm ${!statusFilter ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setStatusFilter('')}>All</button>
        {['pending', 'approved', 'ongoing', 'closed', 'cancelled'].map(status => (
          <button
            key={status}
            className={`btn btn-sm m-1 ${statusFilter === status ? 'btn-primary' : 'btn-outline-primary'} ml-2`}
            onClick={() => setStatusFilter(status)}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </button>
        ))}
        <button className="btn btn-secondary btn-sm ml-4" onClick={toggleViewMode}>
          Toggle {viewMode === 'table' ? 'Timetable' : 'Table'} View
        </button>
      </div>

      {/* Shifts View */}
      {viewMode === 'table' ? (
        // Table View
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Team Player</th>
              <th>Client</th>
              <th>Shift Name</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredShifts.map(shift => (
              <tr key={shift.id}>
                <td>{shift.worker.name}</td>
                <td>{shift.client.name}</td>
                <td>{shift.shift_type === 1 ? 'Active' : 'Inactive'}</td>
                <td>{new Date(shift.start_at).toLocaleString('en-US')}</td>
                <td>{new Date(shift.end_at).toLocaleString('en-US')}</td>
                <td>{shift.status}</td>
                <td>
                  <Link className="btn btn-warning btn-sm mr-2" to={`/shift/${shift.id}`}>View</Link>
                  <button className="btn btn-warning btn-sm mr-2" onClick={() => handleEditShift(shift)}>Edit</button>
                  <button className="btn btn-danger btn-sm" onClick={() => handleDeleteShift(shift.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        // Timetable View
        <div className="timetable-view">
          {filteredShifts.map(shift => (
            <div
              key={shift.id}
              className={`timetable-item timetable-item-${shift.status}`}
              style={{ backgroundColor: getStatusColor(shift.status) }}
            >
              <p><strong>Team Player:</strong> {shift.worker.name}</p>
              <p><strong>Client:</strong> {shift.client.name}</p>
              <p><strong>Start:</strong> {new Date(shift.start_at).toLocaleString('en-US')}</p>
              <p><strong>End:</strong> {new Date(shift.end_at).toLocaleString('en-US')}</p>
            </div>
          ))}
        </div>
      )}

      {/* Modal for Create/Edit Shift */}
      {showModal && (
        <ShiftModal
          shift={selectedShift}
          error={error}
          setShowModal={setShowModal}
          handleSave={handleSaveShift}
        />
      )}
    </div>
  );
};

// Utility function to get color based on status
const getStatusColor = (status) => {
  switch (status) {
    case 'pending': return '#ffcc00';
    case 'approved': return '#4CAF50';
    case 'ongoing': return '#2196F3';
    case 'closed': return '#9E9E9E';
    case 'cancelled': return '#f44336';
    default: return '#ffffff';
  }
};

export default Shifts;
