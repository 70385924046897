// AgentModal.js
import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AgentModal = ({ show, handleClose, agent, handleInputChange, handleSubmit, isEditing }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isEditing ? 'Edit Agent' : 'Add Agent'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={agent.name}
              onChange={handleInputChange}
              placeholder="Enter name"
            />
          </Form.Group>
          <Form.Group controlId="formLName">
            <Form.Label>Postfix</Form.Label>
            <Form.Control
              type="text"
              name="lname"
              value={agent.lname}
              onChange={handleInputChange}
              placeholder="Enter Postfix"
            />
          </Form.Group>
          <Form.Group controlId="formEmail" className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={agent.email}
              onChange={handleInputChange}
              placeholder="Enter email"
            />
          </Form.Group>
          <Form.Group controlId="formPhone" className="mt-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              name="tel"
              value={agent.tel}
              onChange={handleInputChange}
              placeholder="Enter phone number"
            />
          </Form.Group>
          <Form.Group controlId="formState" className="mt-3">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={agent.state}
              onChange={handleInputChange}
              placeholder="Enter State"
            />
          </Form.Group>
          <Form.Group controlId="formzipcode" className="mt-3">
            <Form.Label>Zipcode</Form.Label>
            <Form.Control
              type="text"
              name="zipcode"
              value={agent.zipcode}
              onChange={handleInputChange}
              placeholder="Enter Zipcode"
            />
          </Form.Group>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        <Button variant="primary" onClick={handleSubmit}>
          {isEditing ? 'Update Agent' : 'Save Agent'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AgentModal;
