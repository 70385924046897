import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Loader from '../components/Loader';
import PdfLayout from '../components/PdfLayout';

const Dues = () => {
    const { dueId } = useParams();
    const [dueData, setDueData] = useState(null);
    const [loading, setLoading] = useState(true);
    const componentRef = useRef();

    useEffect(() => {
        const fetchDueDetails = async () => {
            try {
                const response = await axios.get(`/dues-details/${dueId}`);
                setDueData(response.data);
            } catch (error) {
                console.error('Error fetching due details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDueDetails();
    }, [dueId]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `DueDetails-${dueId}`,
    });

    if (loading) {
        return <Loader></Loader>
    }
    if (!dueData) {
        return <p>No data available for this due ID.</p>;
    }

    const { billedShifts, dueDetails } = dueData;
    const { worker, cycle, total_amount } = dueDetails;

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between">
                <h2>Due Details for {worker.name} {worker.lname}</h2>
                <button onClick={handlePrint} className="btn btn-primary">Print PDF</button>
            </div>
            <div ref={componentRef} className="mt-4 p-3 border rounded">
                {/* Header - Company Letterhead */}
                <PdfLayout></PdfLayout>
                {/* Worker and Due Information */}
                <div className="border p-3 mb-4 d-flex justify-content-between">
                    <div>
                        <h4>Worker Details</h4>
                        <p>Name: {worker.name} {worker.mname} {worker.lname}</p>
                        <p>Email: {worker.email}</p>
                        <p>Phone: {worker.tel}</p>
                    </div>
                    <div>
                        <h4>Due Details</h4>
                        <p>Total Amount Due: ${total_amount}</p>
                        <p>Due Date: {new Date(dueDetails.created_at).toLocaleDateString()}</p>
                        <p>Period: {new Date(cycle.start_date).toLocaleDateString()} to {new Date(cycle.end_date).toLocaleDateString()}</p>
                    </div>
                </div>

                {/* Shifts Table */}
                <div className="border p-3 mb-4">
                    <h4>Billed Shifts</h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Shift ID</th>
                                <th>Client ID</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {billedShifts.map((shift) => (
                                <tr key={shift.id}>
                                    <td>{shift.id}</td>
                                    <td>{shift.client_id}</td>
                                    <td>{new Date(shift.start_at).toLocaleString()}</td>
                                    <td>{new Date(shift.end_at).toLocaleString()}</td>
                                    <td>{shift.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Footer */}
                <footer className="text-center mt-4">
                    <p>Thank you for your service!</p>
                </footer>
            </div>
        </div>
    );
};

export default Dues;