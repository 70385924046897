import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PaymentModal = ({ show, onClose, paymentData }) => {
    const [formData, setFormData] = useState({
        nurse_id: '',
        invoice_id: '',
        amount_paid: '',
        account: '',
        payment_ref: '',
        paid_at: ''
    });

    useEffect(() => {
        if (paymentData) {
            setFormData(paymentData); // Prepopulate form if updating
        }
    }, [paymentData]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (paymentData) {
                // Update payment
                await axios.put(`/payments/${paymentData.id}`, formData);
            } else {
                // Create new payment
                await axios.post('/payments', formData);
            }

            onClose(); // Close the modal
        } catch (error) {
            console.error('Error submitting payment:', error);
        }
    };

    if (!show) return null;

    return (
        <div className="modal" style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{paymentData ? 'Update Payment' : 'Record Payment'}</h5>
                        <button type="button" className="close" onClick={onClose}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="nurse_id">Nurse ID</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nurse_id"
                                    name="nurse_id"
                                    value={formData.nurse_id}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="invoice_id">Invoice ID</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="invoice_id"
                                    name="invoice_id"
                                    value={formData.invoice_id}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="amount_paid">Amount Paid</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="amount_paid"
                                    name="amount_paid"
                                    value={formData.amount_paid}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="account">Account</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="account"
                                    name="account"
                                    value={formData.account}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="payment_ref">Payment Reference</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="payment_ref"
                                    name="payment_ref"
                                    value={formData.payment_ref}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="paid_at">Payment Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="paid_at"
                                    name="paid_at"
                                    value={formData.paid_at}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                {paymentData ? 'Update Payment' : 'Record Payment'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentModal;
