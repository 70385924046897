import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Loader from '../components/Loader';
import PdfLayout from '../components/PdfLayout';

const Invoice = () => {
    const { invoiceId } = useParams();
    const [invoiceData, setInvoiceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const componentRef = useRef(null);

    useEffect(() => {
        const fetchInvoiceDetails = async () => {
            try {
                const response = await axios.get(`/invoice-details/${invoiceId}`);
                setInvoiceData(response.data);
            } catch (error) {
                console.error('Error fetching invoice details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInvoiceDetails();
    }, [invoiceId]);
    useEffect(() => {
        if (componentRef.current) {
            console.log("Ref is set:", componentRef.current);
        } else {
            console.error("Ref is not attached ");
        }
    }, []);
    
    const handlePrint = useReactToPrint({
        
        contentRef: () => componentRef, // Access the ref
        documentTitle: `InvoiceDetails-${invoiceId}`,
        onBeforePrint: () => {
            console.log("x");
        },
        onAfterPrint: () => console.log('Print job completed!'),
    });    

    if (loading) {
        return <Loader />;
    }
    if (!invoiceData) {
        return <p>No data available for this invoice ID.</p>;
    }

    const { billedShifts, invoiceDetails } = invoiceData;
    const { client, cycle, total_amount } = invoiceDetails;

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between">
                <h2>Invoice Details for {client.name} {client.lname}</h2>
                <button onClick={handlePrint} className="btn btn-primary">Print PDF</button>
            </div>
            {/* Ref is attached directly to the component */}
            <div ref={(el) => (componentRef.current = el)} className="mt-4 p-3 border rounded">
            {/* Header - Company Letterhead */}
                <PdfLayout></PdfLayout>

                {/* Client and Invoice Information */}
                <div className="border p-3 mb-4">
                    <div className='d-flex justify-content-between'>
                        <div className='p-3'>
                            <h4>Client Details</h4>
                            <p>Name: {client.name} {client.mname} {client.lname}</p>
                            <p>Email: {client.email}</p>
                            <p>Phone: {client.tel}</p>
                        </div>
                        <div className='p-3'>
                            <h4>Invoice Details</h4>
                            <p>Total Amount Invoice: ${total_amount}</p>
                            <p>Invoice Date: {new Date(invoiceDetails.created_at).toLocaleDateString()}</p>
                            <p>Period: {new Date(cycle.start_date).toLocaleDateString()} to {new Date(cycle.end_date).toLocaleDateString()}</p>
                        </div>
                    </div>
                </div>

                {/* Shifts Table */}
                <div className="border p-3 mb-4">
                    <h4>Billed Shifts</h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Shift ID</th>
                                <th>Client ID</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {billedShifts.map((shift) => (
                                <tr key={shift.id}>
                                    <td>{shift.id}</td>
                                    <td>{shift.client_id}</td>
                                    <td>{new Date(shift.start_at).toLocaleString()}</td>
                                    <td>{new Date(shift.end_at).toLocaleString()}</td>
                                    <td>{shift.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Footer */}
                <footer className="text-center mt-4">
                    <p>Thank you for choosing us!</p>
                </footer>
            </div>
        </div>
    );
};

export default Invoice;
