import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import PdfLayout from '../components/PdfLayout';

const Cycle = () => {
    const { cycleId } = useParams();
    const [cycleData, setCycleData] = useState(null);
    const [loading, setLoading] = useState(true);
    const componentRef = useRef();


    useEffect(() => {
        const fetchCycleDetails = async () => {
            try {
                const response = await axios.get(`/cycle/${cycleId}`);
                setCycleData(response.data);
            } catch (error) {
                console.error('Error fetching cycle details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCycleDetails();
    }, [cycleId]);
    if (loading) {
        return <Loader></Loader>
    }
    if (!cycleData) {
        return <p>No data available for this Billing Cycle.</p>;
    }
    const { incomes, dues } = cycleData;
    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between">
                <h4>Billing Cycle Details: {new Date(cycleData.start_date).toLocaleDateString()} to {new Date(cycleData.end_date).toLocaleDateString()}</h4>
                <button className="btn btn-primary">Print PDF</button>
            </div>
            <div ref={componentRef} className="mt-4 p-3 border rounded">
                {/* Header - Company Letterhead */}
                <PdfLayout></PdfLayout>

                {/* Billing Cycle Information */}
                <div className="border p-3 mb-4 text-center">
                    <h4>Billing Cycle</h4>
                    <p>Period: {new Date(cycleData.start_date).toLocaleDateString()} to {new Date(cycleData.end_date).toLocaleDateString()}</p>
                    <p>Income Total: ${cycleData.income_total}</p>
                    <p>Dues Total: ${cycleData.dues_total}</p>
                </div>

                <div className='row'>
                    {/*Dues */}
                    <div className='border p-3 col-lg-6'>
                        <h4>Dues</h4>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Support Worker</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dues.map((due) => (
                                    <tr key={due.id}>
                                        <td>{due.worker.name + " " + due.worker.lname}</td>
                                        <td>{Number(due.total_amount).toLocaleString('en-US')}</td>
                                        <td>{due.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className='border p-3 col-lg-6'>
                        <h4>Income</h4>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Client</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {incomes.map((invoice) => (
                                    <tr key={invoice.id}>
                                        <td>{invoice.client.name + " " + invoice.client.lname}</td>
                                        <td>{Number(invoice.total_amount).toLocaleString('en-US')}</td>
                                        <td>{invoice.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Cycle;