import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../context/ContextProvider';

function RegisterUser() {
    const [agencies, setAgents] = useState([]);
    const [carePackages, setCarePackages] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        mname: '',
        lname: '',
        email: '',
        tel: 0,
        level: 0,
        state: '',
        zipcode: '',
        password: '',
        password_confirmation: '',
        start: '',
        end: '',
        care_package_id:0,
        certification: '',
        cert_documentation: null,
        agency_id: ''
    });
    const { setToken } = useStateContext;
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        console.log(e.target.name + ":" + e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(formData);
            const response = await axios.post('/users', formData);
            alert(response.data.message);
            navigate('/users');
        } catch (err) {
            if (err.response && err.response.data) {
                setError(err.response.data.msg);
                console.log(err);
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };
    useEffect(() => {
        const fetchCarePackages = async () => {
            try {
                const response = await axios.get('/care-packages');
                setCarePackages(response.data);
                carePackages.value = response.data;
            } catch (error) {
                console.error('Error fetching care packages:', error);
            }
        };
        fetchCarePackages();
    }, []);
    useEffect(() => {
        //Fetch agencies
        const fetchAgencies = async () => {
            try {
                const response = await axios.get('/agents');
                agencies.value = response.data;
                setAgents(response.data);
                console.log(agencies);
            } catch (error) {
                console.error('Error fetching agencies:', error);
            }
        };
        fetchAgencies();
    }, []);

    return (
        <div className="register-admin container mt-5 shadow">
            <h2 className="text-center mb-4">Register User</h2>
            {error && <p className="alert alert-danger">{error}</p>}
            <form onSubmit={handleSubmit} className="row g-3">
                <div className="col-md-4">
                    <label className="form-label">First Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                    {error?.name && <p className="error">{error.name[0]}</p>}
                </div>
                <div className="col-md-4">
                    <label className="form-label">Middle Name</label>
                    <input
                        type="text"
                        name="mname"
                        value={formData.mname}
                        onChange={handleChange}
                        className="form-control"
                    />
                    {error?.mname && <p className="error">{error.mname[0]}</p>}
                </div>
                <div className="col-md-4">
                    <label className="form-label">Last Name</label>
                    <input
                        type="text"
                        name="lname"
                        value={formData.lname}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                    {error?.lname && <p className="error">{error.lname[0]}</p>}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                    {error?.email && <p className="error">{error.email[0]}</p>}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Telephone</label>
                    <input
                        type="tel"
                        name="tel"
                        value={formData.tel}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                    {error?.tel && <p className="error">{error.tel[0]}</p>}
                </div>
                <div className="col-md-6">
                    <label className="form-label">State</label>
                    <select
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        className="form-control"
                        required>
                        <option value="">Select State</option>
                        <option value="1">Victoria</option>
                        {/* <option value="3">Intern</option>
                        <option value="4">Client</option> */}
                    </select>
                    {error?.state && <p className="error">{error.state[0]}</p>}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Zipcode</label>
                    <input
                        type="text"
                        name="zipcode"
                        value={formData.zipcode}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                    {error?.zipcode && <p className="error">{error.zipcode[0]}</p>}
                </div>
                <div className="col-md-6">
                    <label>Level</label>
                    <select className="form-control" value={formData.level} onChange={handleChange} name='level'>
                        <option value="">Select level</option>
                        <option value="2">Worker</option>
                        <option value="3">Intern</option>
                        <option value="4">Client</option>
                    </select>
                </div>
                {formData.level == 4 ? (
                    <>
                        <div className="col-md-6">
                            <label>Agency</label>
                            <select
                                className="form-control"
                                value={formData.agencyId}
                                name='agency_id'
                                onChange={handleChange}
                            >
                                <option value="">Select Agency</option>
                                {agencies.map((agency) => (
                                    <option key={agency.id} value={agency.id}>
                                        {agency.name + " " + agency.lname}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label>Care Package</label>
                            <select
                                className="form-control"
                                value={formData.care_package_id}
                                name='care_package_id'
                                onChange={handleChange}
                            >
                                <option value="">Select Package</option>
                                {carePackages.map((cpackage) => (
                                    <option key={cpackage.id} value={cpackage.id}>
                                        {cpackage.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                ) : (
                    <div className="col-md-6">
                        <label>Qualification</label>
                        <select
                            className="form-control"
                            value={formData.qualification}
                            onChange={handleChange}
                            name='qualification'
                        >
                            <option value="">Select Qualification</option>
                            <option value="1">Intern</option>
                            <option value="2">Professional</option>
                        </select>
                    </div>
                )}
                <div className="col-md-6">
                    <label className="form-label">Password</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                    {error?.password && <p className="error">{error.password[0]}</p>}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Confirm Password</label>
                    <input
                        type="password"
                        name="password_confirmation"
                        value={formData.password_confirmation}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                    {error?.password_confirmation && <p className="error">{error.password_confirmation[0]}</p>}
                </div>
                <div className="col-12 text-center">
                    <button type="submit" className="btn btn-gold w-50">Save User</button>
                </div>
            </form>
        </div>
    );
}

export default RegisterUser;